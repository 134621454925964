exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-algemene-voorwaarden-tsx": () => import("./../../../src/pages/algemene-voorwaarden.tsx" /* webpackChunkName: "component---src-pages-algemene-voorwaarden-tsx" */),
  "component---src-pages-cases-tsx": () => import("./../../../src/pages/cases.tsx" /* webpackChunkName: "component---src-pages-cases-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kennis-tsx": () => import("./../../../src/pages/kennis.tsx" /* webpackChunkName: "component---src-pages-kennis-tsx" */),
  "component---src-pages-over-parley-tsx": () => import("./../../../src/pages/over-parley.tsx" /* webpackChunkName: "component---src-pages-over-parley-tsx" */),
  "component---src-pages-privacy-statement-tsx": () => import("./../../../src/pages/privacy-statement.tsx" /* webpackChunkName: "component---src-pages-privacy-statement-tsx" */),
  "component---src-pages-probeer-parley-tsx": () => import("./../../../src/pages/probeer-parley.tsx" /* webpackChunkName: "component---src-pages-probeer-parley-tsx" */),
  "component---src-pages-security-compliance-tsx": () => import("./../../../src/pages/security-compliance.tsx" /* webpackChunkName: "component---src-pages-security-compliance-tsx" */),
  "component---src-templates-single-application-page-tsx": () => import("./../../../src/templates/SingleApplicationPage.tsx" /* webpackChunkName: "component---src-templates-single-application-page-tsx" */),
  "component---src-templates-single-case-page-tsx": () => import("./../../../src/templates/SingleCasePage.tsx" /* webpackChunkName: "component---src-templates-single-case-page-tsx" */),
  "component---src-templates-single-channel-page-tsx": () => import("./../../../src/templates/SingleChannelPage.tsx" /* webpackChunkName: "component---src-templates-single-channel-page-tsx" */),
  "component---src-templates-single-integration-page-tsx": () => import("./../../../src/templates/SingleIntegrationPage.tsx" /* webpackChunkName: "component---src-templates-single-integration-page-tsx" */),
  "component---src-templates-single-knowledge-article-page-tsx": () => import("./../../../src/templates/SingleKnowledgeArticlePage.tsx" /* webpackChunkName: "component---src-templates-single-knowledge-article-page-tsx" */),
  "component---src-templates-single-sector-page-tsx": () => import("./../../../src/templates/SingleSectorPage.tsx" /* webpackChunkName: "component---src-templates-single-sector-page-tsx" */),
  "component---src-templates-single-whitepaper-page-tsx": () => import("./../../../src/templates/SingleWhitepaperPage.tsx" /* webpackChunkName: "component---src-templates-single-whitepaper-page-tsx" */)
}

